import * as React from 'react';
import { graphql } from 'gatsby';

import ArticlesArchive from './articles-archive';

const FreelancingArticles = ({ data, pageContext }) => {
  const { numPages, currentPage } = pageContext;
  return (
    <ArticlesArchive
      type='Freelancing'
      currentPage={currentPage}
      numPages={numPages}
      description={`Articles that will help you become a freelance web developer and sustain your independence`}
      articles={data.allMdx.edges}
      stub='freelancing'
    />
  );
};
export default FreelancingArticles;

export const query = graphql`
  query FreelancingArticles($limit: Int!, $skip: Int!) {
    allMdx(
      filter: { frontmatter: { tags: { eq: "freelancing" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            coverImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              publicURL
            }
            title
            tags
            date
            description
          }
        }
      }
    }
  }
`;
